import { useRouter } from 'next/router';
import { useEffect } from 'react';

function Home({}) {
  const router = useRouter();
  useEffect(() => {
    router.push('/dashboard');
  }, [router]);
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <p className="text-giveGray-600 text-2xl font-light">Loading...</p>
    </div>
  );
}

Home.title = 'Homepage';

export default Home;
